import React, { useEffect, useState } from "react";
import { Sortable } from "../../Behavior";
import { ItemTypes } from "./ItemTypes";
import { Sendable } from "./Sendable";
import { Thumbnail } from "./FilePreview";
export var ImagePreview = function (_a) {
    var id = _a.id, canDrop = _a.canDrop, primary = _a.primary, preview = _a.preview, onSort = _a.onSort, onFinishSort = _a.onFinishSort, onDelete = _a.onDelete, index = _a.index, progress = _a.progress;
    var _b = useState(null), localPreview = _b[0], setLocalPreview = _b[1];
    if (!localPreview && preview && preview.file) {
        setLocalPreview(URL.createObjectURL(preview.file));
    }
    useEffect(function () {
        if (localPreview) {
            URL.revokeObjectURL(localPreview);
        }
    }, [localPreview]);
    return (React.createElement(Sortable, { id: id, index: index, type: ItemTypes.Image, onSort: onSort, onFinishSort: onFinishSort, sortDirection: "horizontal" },
        React.createElement(Thumbnail, { primary: primary },
            React.createElement(Sendable, { onDelete: onDelete, deletable: canDrop, preview: preview, progress: progress }, preview ? (React.createElement("img", { src: localPreview || preview.downloadUrl || "" })) : null))));
};
