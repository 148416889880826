import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
/**
 * A container that allows the child component to use drag
 * and drop for simple sorting.
 * @param props Props for this component.
 */
export var Sortable = function (_a) {
    var id = _a.id, onFinishSort = _a.onFinishSort, onSort = _a.onSort, index = _a.index, type = _a.type, _b = _a.accept, accept = _b === void 0 ? type : _b, children = _a.children, sortDirection = _a.sortDirection, className = _a.className, _c = _a.disabled, disabled = _c === void 0 ? false : _c;
    var ref = useRef(null);
    var _d = useDrop(function () { return ({
        accept: accept,
        canDrop: function () {
            var enabled = disabled !== null && disabled !== void 0 ? disabled : true;
            return enabled;
        },
        hover: function (item, monitor) {
            var node = ref.current;
            if (!node) {
                return null;
            }
            var dragIndex = item.index;
            var hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            var hoverBoundingRect = node.getBoundingClientRect();
            // Get upper / left bound
            var hoverMiddle = {
                x: (hoverBoundingRect.right - hoverBoundingRect.left) / 2,
                y: (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2,
            };
            // Determine mouse position
            var clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            var hoverClient = {
                x: clientOffset.x - hoverBoundingRect.left,
                y: clientOffset.y - hoverBoundingRect.top,
            };
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Only perform the move when the mouse has crossed half of the items width
            // When dragging downward/rightward, only move when the cursor is to the below/right of 50%
            // When dragging upward/leftward, only move when the cursor is above/left of 50%
            // Dragging right or down
            if (dragIndex < hoverIndex && sortDirection === 'horizontal'
                ? hoverClient.x < hoverMiddle.x
                : hoverClient.y < hoverMiddle.y) {
                return;
            }
            // Dragging left or up
            if (dragIndex > hoverIndex && sortDirection === 'horizontal'
                ? hoverClient.x > hoverMiddle.x
                : hoverClient.y > hoverMiddle.y) {
                return;
            }
            // Time to actually perform the action
            onSort(item.id, dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            monitor.getItem().index = hoverIndex;
        },
    }); }, [disabled, index, onSort]), drop = _d[1];
    var _e = useDrag(function () { return ({
        type: type,
        item: { type: type, id: id, index: index, onSort: onSort, onFinishSort: onFinishSort, disabled: disabled },
        end: function (dropResult) {
            dropResult === null || dropResult === void 0 ? void 0 : dropResult.onFinishSort();
        },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }); }, [onFinishSort]), isDragging = _e[0].isDragging, drag = _e[1];
    // Hook up the drag and drop actions to a reference of
    // our container object.
    drag(drop(ref));
    return (React.createElement("div", { ref: ref, style: { opacity: isDragging ? 0.5 : 1 }, className: className }, children));
};
