var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { Icon } from './Icon';
import { ToolTip } from '../Navigation';
export var IconButton = function (_a) {
    var onClick = _a.onClick, props = __rest(_a, ["onClick"]);
    var _b = useState(false), hover = _b[0], setHover = _b[1];
    var handleClick = function (e) {
        e.preventDefault();
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    var handleHover = function (state) { return function () { return setHover(state); }; };
    return (React.createElement("button", { className: "flex items-center h-8 w-8 rounded-full hover:bg-primary hover:bg-opacity-10 relative", onClick: handleClick, onMouseOver: handleHover(true), onMouseOut: handleHover(false) },
        React.createElement(Icon, __assign({}, props)),
        props.label ? React.createElement(ToolTip, __assign({}, props, { active: hover })) : null));
};
