import React from 'react';
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { concatStyles } from 'utils';
var CONTAINER_STYLES = 'flex flex-col text-center h-full font-bold text-primary font-body p-2 w-full';
var ICON_WRAPPER_STYLES = 'text-xl rounded-full h-10 w-10 text-center mx-auto mt-auto flex bg-button';
var ICON_STYLES = 'm-auto';
var ACTIVE_STYLES = 'bg-white text-button border-2 border-button';
var INACTIVE_STYLES = 'bg-button text-white border-2 border-transparent';
var LABEL_STYLES = 'mx-auto mt-0 mb-auto';
var ACTIVE_LABEL_STYLES = 'text-button';
export var DropTarget = function (_a) {
    var _b = _a.label, label = _b === void 0 ? "Drag 'n' drop some files here, or click to select files" : _b, active = _a.active;
    return (React.createElement("div", { className: concatStyles([CONTAINER_STYLES, active ? ACTIVE_STYLES : '']) },
        React.createElement("p", { className: concatStyles([
                ICON_WRAPPER_STYLES,
                active ? ACTIVE_STYLES : INACTIVE_STYLES,
            ]) },
            React.createElement(FontAwesomeIcon, { icon: faArrowUp, className: ICON_STYLES })),
        React.createElement("p", { className: concatStyles([
                LABEL_STYLES,
                active ? ACTIVE_LABEL_STYLES : '',
            ]) }, label)));
};
