import * as React from 'react';
import * as Card from '../Card';
import * as Form from '../Form';
import * as Heading from '../Heading';
import * as Para from '../Para';
import * as Modal from './Modal';
export var Dialog = function (p) { return (React.createElement(Modal.Container, { onClick: p.onDismiss, transparent: true },
    React.createElement(Modal.ContentRegion, { size: Modal.Size.small },
        React.createElement(Card.View, null,
            React.createElement(Heading.Three, null, p.title),
            React.createElement(Para.Book, null, p.message),
            React.createElement(Form.ButtonGroup, null,
                React.createElement(Form.Button, { onClick: p.onDismiss },
                    React.createElement("span", null, "Cancel")),
                React.createElement(Form.Button, { onClick: p.onConfirm, destructive: p.destructive },
                    React.createElement("span", null, "Confirm"))))))); };
