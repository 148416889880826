import { clearCredentials } from './clearCredentials';
import { accessTokenPresent, getCurrentAccessLevel, getCurrentUserId, } from './credentials';
import { setCredentials } from './setCredentials';
import { UPDATE_SESSION, AccessLevel, } from './types';
export var updateCredentials = function (creds) {
    setCredentials(creds);
};
export var updateSession = function (newSession) {
    updateCredentials(newSession);
    return {
        payload: {
            accessLevel: getCurrentAccessLevel(),
            isAuthenticated: accessTokenPresent(),
            sessionInvalidated: false,
            userId: getCurrentUserId(),
        },
        type: UPDATE_SESSION,
    };
};
export var clearSession = function (flags) {
    clearCredentials();
    return {
        payload: {
            accessLevel: AccessLevel.User,
            isAuthenticated: accessTokenPresent(),
            sessionInvalidated: (flags && flags.invalidSession) || false,
            userId: getCurrentUserId(),
        },
        type: UPDATE_SESSION,
    };
};
