import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { PreviewAction } from "./PreviewAction";
import { concatStyles } from "utils";
import { Progress } from "./Progress";
var CONTAINER_STYLES = "flex relative w-full flex-grow";
export var Sendable = function (_a) {
    var deletable = _a.deletable, preview = _a.preview, onDelete = _a.onDelete, progress = _a.progress, children = _a.children, listMode = _a.listMode;
    var _b = useState(false), hovering = _b[0], setHovering = _b[1];
    var _c = useState(null), hoverTimeout = _c[0], setHoverTimeout = _c[1];
    var deletePreview = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (onDelete) {
            onDelete(preview);
        }
    };
    var toggleHover = function (enabled) { return function () {
        if (hoverTimeout && enabled) {
            clearTimeout(hoverTimeout);
        }
        if (!enabled) {
            setHoverTimeout(setTimeout(function () { return setHovering(enabled); }, 25));
        }
        else {
            setHovering(enabled);
        }
    }; };
    var deleteVisible = !deletable && hovering ? true : false;
    var loading = progress && progress < 100;
    return (React.createElement("div", { className: CONTAINER_STYLES, onMouseOver: toggleHover(true), onMouseOut: toggleHover(false), onMouseDown: toggleHover(false) },
        onDelete ? (React.createElement(PreviewAction, { onClick: deletePreview, onMouseOver: toggleHover(true), visible: deleteVisible, verticallyCentered: listMode },
            React.createElement(FontAwesomeIcon, { icon: faTrash, className: "m-auto" }))) : (React.createElement("span", null)),
        React.createElement("div", { className: concatStyles([
                CONTAINER_STYLES,
                loading ? "opacity-50" : "",
            ]) }, children),
        loading ? React.createElement(Progress, { progress: progress }) : null));
};
