import React from 'react';
import { Label, RequiredIcon } from '../Form';
import { Error } from './Error';
import { FieldContainer, InputWrapper } from './FieldContainer';
import { Icon } from './Icon';
/**
 * A simple non-context based field renderer for use with Formik
 * when utilizing the Hooks API. Most fields will be migrated to
 * this format as it's much easier to maintain and understand.
 *
 * @param props Configuration for this component.
 */
export var Field = function (_a) {
    var children = _a.children, error = _a.error, icon = _a.icon, label = _a.label, name = _a.name, required = _a.required;
    return (React.createElement(FieldContainer, null,
        label && (React.createElement(Label, { htmlFor: name },
            label,
            required && React.createElement(RequiredIcon, null))),
        React.createElement(InputWrapper, null,
            children,
            icon ? React.createElement(Icon, { icon: icon }) : React.createElement("span", null)),
        error ? React.createElement(Error, null, error) : null));
};
